// PrivacyPolicy.js

import React from 'react';

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <h2>Privacy Policy</h2>
      <p>Last updated: Feb 10, 2024</p>
      
      <p>
        YellowMythics ("us", "we", or "our") operates the YellowMythics website (the "Service").
        This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.
      </p>

      <p>
        We do not collect any personal information from our users. We do not use cookies or any other tracking mechanisms on our website.
      </p>

      <h3>Information Collection And Use</h3>

      <p>
        We do not collect any information about you or your usage of our Service.
      </p>

      <h3>Use of Data</h3>

      <p>
        We do not use any data collected from our users, as we do not collect any data.
      </p>

      <h3>Transfer Of Data</h3>

      <p>
        As we do not collect any personal data, there is no transfer of data to third parties.
      </p>

      <h3>Data Security</h3>

      <p>
        We prioritize the security of our users' data, even though we do not collect any personal data. Any information stored in local storage (localStorage) is encrypted and accessible only by the user's browser.
      </p>

      <h3>Changes To This Privacy Policy</h3>

      <p>
        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
      </p>

      <h3>Contact Us</h3>

      <p>
        If you have any questions about this Privacy Policy, please contact us:
        <ul>
          <li>By email: info@yellowmythics.com</li>
        </ul>
      </p>

      <h3>Additional Information</h3>

      <p>
        This Privacy Policy is effective as of Feb 10, 2024 and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.
      </p>

      <p>
        We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.
      </p>
    </div>
  );
}

export default PrivacyPolicy;
