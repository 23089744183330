import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Navbar from './Navbar.js';
import MyCollection from './MyCollection.js';
import Mythics from './Mythics.js';
import Stats from './Stats.js';
import Leaderboard from './Leaderboard.js';
import Footer from './Foot.js';
import PrivacyPolicy from './PrivacyPolicy.js';
import NotFound from './404.js';
import { WalletProvider } from './WalletContext.js'; 
import './styles/App.css';

function App() {
    return (
        <Router>
            <WalletProvider>
                <div className="App">
                    <Navbar />
                    <div className="container">
                        <div className="content">
                            <Routes>
                                <Route path="/stats" element={<Stats />} /> 
                                <Route path="/mythics" element={<Mythics />} />
                                <Route path="/leaderboard" element={<Leaderboard />} />
                                <Route path="/portfolio" element={<MyCollection />} /> 
                                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                                <Route path="/404" element={<NotFound />} />
                                <Route path="*" element={<Navigate to="/404" />} />
                                <Route path="/" element={<Navigate to="/stats/" />} />
                            </Routes>
                        </div>
                    </div>
                    <Footer />
                </div>
            </WalletProvider>
        </Router>
    );
}

export default App;
