import { db } from './firebase.js'; // Ensure this is the correct path to your firebase.js
import { doc, getDoc } from 'firebase/firestore';

// Function to fetch the latest stats data
export async function fetchStatsData() {
    const docRef = doc(db, 'stats', 'latest');
    try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            // Convert document data into an array of { date, totalMinted, uniqueMinted, totalOwners }
            const statsData = docSnap.data();
            const formattedData = Object.entries(statsData).map(([date, values]) => ({
                date,
                ...values
            }));
            return formattedData;
        } else {
            console.log("No such document!");
            return [];
        }
    } catch (error) {
        console.error("Error fetching stats data: ", error);
        throw error; // Rethrow the error to handle it in the calling component
    }
}
