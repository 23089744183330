import React, { useState, useEffect } from 'react';
import { fetchLeaderboardData } from './db/dbLeaderboard.js';
import './styles/Leaderboard.css'; // Create this file for custom styles if needed
import { useWallet } from './WalletContext.js';
import { getOpenSeaAccountName } from './api/opensea.js'; // Import the getOpenSeaAccountName function

function Leaderboard() {
  const { walletAddress } = useWallet();
  const [ownerStats, setOwnerStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState({ id: 'points', desc: true });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [username, setUsername] = useState(''); // State to store the username

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      let data = localStorage.getItem('leaderboardData');
      if (!data) {
        // Fetch data from the database if not available in local storage
        data = await fetchLeaderboardData();
        localStorage.setItem('leaderboardData', JSON.stringify(data));
      } else {
        data = JSON.parse(data);
      }
      // Replace wallet addresses with OpenSea usernames where available
      const updatedStats = await Promise.all(
        data.ownerStatisticsArray.map(async (owner) => {
          if (owner.owner) {
            const username = await getOpenSeaAccountName(owner.owner);
            owner.owner =
              username !== 'Account not found' ? username : owner.owner;
          }
          return owner;
        })
      );
      setOwnerStats(updatedStats);
      setLoading(false);
    };

    fetchData();

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (walletAddress) {
      fetchUsername(walletAddress);
    }
  }, [walletAddress, username]);

  // Function to fetch username from OpenSea API
  const fetchUsername = async (address) => {
    if (address) {
      const username = await getOpenSeaAccountName(address);
      setUsername(username);
    }
  };

  const sortColumns = [
    { id: 'totalNFTs', label: 'Total Mythics' },
    { id: 'uniqueNFTs', label: 'Unique Mythics' },
    { id: 'strikeRank', label: 'Rank Streak' },
    { id: 'highestRank', label: 'Highest Rank' },
    { id: 'totalRank', label: 'Total Rank' },
    { id: 'points', label: 'Power' },
  ];  

  const sortedOwnerStats = ownerStats
  .map(owner => ({ ...owner, points: (owner.highestRank * owner.strikeRank) + owner.totalRank })) // This step is optional if you've pre-calculated points
  .slice()
  .sort((a, b) => {
    const isDesc = sortBy.desc ? -1 : 1;
    let aValue = a[sortBy.id];
    let bValue = b[sortBy.id];

    // If sorting by points, ensure the values are correctly interpreted
    if (sortBy.id === 'points') {
      aValue = (a.highestRank * a.strikeRank) + a.totalRank;
      bValue = (b.highestRank * b.strikeRank) + b.totalRank;
    }

    if (aValue < bValue) return -1 * isDesc;
    if (aValue > bValue) return 1 * isDesc;
    return 0;
  });

  const handleSort = (columnId) => {
    const newSortBy = {
      id: columnId,
      desc: sortBy.id === columnId ? !sortBy.desc : true,
    };
    setSortBy(newSortBy);
  };

  const highlightRow = (owner) => {
    // Check if the wallet is connected and if the owner matches the connected wallet or username
    const walletIsConnected = !!walletAddress;
    const ownerMatchesWallet = walletIsConnected && owner.owner.toLowerCase() === walletAddress.toLowerCase();
    const ownerMatchesUsername = walletIsConnected && username && owner.owner.toLowerCase() === username.toLowerCase();
  
    return (ownerMatchesWallet || ownerMatchesUsername) ? 'highlight' : '';
  };
  

  const formatWalletAddress = (address) => {
    // Check if address is longer than 15 characters
    if (address.length > 15) {
      // Check if window width is less than or equal to 768
      return windowWidth <= 768
        ? `${address.slice(0, 5)}...${address.slice(-4)}`
        : address;
    } else {
      // Return the original address if it's 15 characters or shorter
      return address;
    }
  };  

  return (
    <div className="Leaderboard">
      <div className="table-container">
        {loading ? (
          <div className="loading">Loading...</div>
        ) : (
          <table>
            <thead>
  <tr>
    <th className='position'>#</th>
    <th onClick={() => handleSort('owner')}>Owner</th>
    {sortColumns.map((column) => (
      <th key={column.id} onClick={() => handleSort(column.id)}>
        {column.label}
        {sortBy.id === column.id && (
          sortBy.desc ? ' ▼' : ' ▲'
        )}
      </th>
    ))}
  </tr>
</thead>
<tbody>
  {sortedOwnerStats.map((owner, index) => (
    <tr key={index} className={highlightRow(owner)}>
      <td>{index + 1}</td>
      <td>{owner.owner ? formatWalletAddress(owner.owner) : "No username found"}</td>
      <td>{owner.totalNFTs}</td>
      <td>{owner.uniqueNFTs}</td>
      <td>{owner.strikeRank}</td>
      <td>{owner.highestRank}</td>
      <td>{owner.totalRank}</td>
      <td>{owner.points}</td>
    </tr>
  ))}
</tbody>

          </table>
        )}
      </div>
    </div>
  );
}

export default Leaderboard;
