// coingecko.js
import axios from 'axios';

const getConversionRates = async () => {
  try {
    console.log('Fetching conversion rates...');
    const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=ethereum,matic-network,usd-coin&vs_currencies=usd');
    console.log('Conversion rates fetched successfully:', response.data);
    const rates = response.data;
    console.log('Conversion Rates:', rates); // Log the rates to inspect the data structure
    return rates;
  } catch (error) {
    console.error('Error fetching conversion rates:', error);
    return {}; // Return an empty object in case of error
  }
};

export default getConversionRates;
