// Assuming localStorage or a similar mechanism for caching
// This example uses localStorage for simplicity

const API_KEY = 'abcfec62fa6642b89e068f15005f033b'; // Replace with your actual API key

export async function getOpenSeaAccountName(walletAddress) {
    // First, check if the username is cached
    const cachedUsername = localStorage.getItem(walletAddress);
    if (cachedUsername) {
        return cachedUsername; // Return cached username if available
    }

    const openSeaAPIURL = `https://api.opensea.io/api/v2/accounts/${walletAddress}`;
    const headers = { 'X-API-KEY': API_KEY };

    try {
        const response = await fetch(openSeaAPIURL, { headers });

        if (!response.ok) {
            throw new Error('Failed to fetch');
        }

        const data = await response.json();

        if (data && data.username) {
            // Cache the username with the wallet address as the key
            localStorage.setItem(walletAddress, data.username);
            return data.username;
        } else {
            // Cache the wallet address to avoid repeated failed requests
            localStorage.setItem(walletAddress, walletAddress);
            return walletAddress; // Display wallet address if username is not available
        }
    } catch (error) {
        console.error("Error fetching OpenSea account name:", error);
        // Fallback to the wallet address on error
        return walletAddress;
    }
}
