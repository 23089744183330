// 404.js
import React from 'react';
import NotFoundImage from './images/404.png'; 
import './styles/404.css'; // Import the CSS file for styling

function NotFoundPage() {
  return (
    <div className="not-found-container">
      <img src={NotFoundImage} alt="404 Error" width="193" height="178" />
      <h1>Oops! Page not found.</h1>
      <p>We can't seem to find the page you're looking for.</p>
    </div>
  );
}

export default NotFoundPage;
