import React, { useEffect, useState } from 'react';
import { fetchStatsData } from './db/dbStats.js'; // Adjust the import path as necessary
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import moment from 'moment';
import './styles/Stats.css'; // Ensure you have this CSS file for styling

const Stats = () => {
    const [statsArray, setStatsArray] = useState([]);
    const [finalTotals, setFinalTotals] = useState({
        totalMinted: 0,
        uniqueMinted: 0,
        totalOwners: 0,
    });

    useEffect(() => {
        const getStatsData = async () => {
            const data = await fetchStatsData();
            if (data) {
                setStatsArray(data.sort((a, b) => new Date(a.date) - new Date(b.date)));
                const lastEntry = data[data.length - 1];
                setFinalTotals({
                    totalMinted: lastEntry.totalMinted,
                    uniqueMinted: lastEntry.uniqueMinted,
                    totalOwners: lastEntry.totalOwners,
                });
            }
        };

        getStatsData();
    }, []);
    const generateChartData = (key) => ({
        labels: statsArray.map(entry => moment(entry.date).format('DD MMM, YY')),
        datasets: [{
            label: `Total ${key.charAt(0).toUpperCase() + key.slice(1)}`,
            data: statsArray.map(entry => entry[key]),
            borderColor: '#022549',
            backgroundColor: 'rgba(2, 37, 73, 1)', // 100% opacity
            fill: true,
            tension: 0.1,
            pointBackgroundColor: 'white', // white points
            pointRadius: 5,
            pointHoverRadius: 7,
        }],
    });

    const options = {
        scales: {
            x: {
                display: false, // No labels on x-axis
            },
            y: {
                display: false, // No labels on y-axis
            },
        },
        plugins: {
            legend: {
                display: false, // Hide legend
            },
        },
        interaction: {
            intersect: false,
            mode: 'nearest',
        },
        elements: {
            line: {
                borderWidth: 3,
            },
        },
        maintainAspectRatio: false,
    };

    return (
        <div className="stats-container" style={{ minHeight: '50vh' }}>
            <div className="stat-container" style={{ height: '200px' }}>
                <Line data={generateChartData('totalMinted')} options={options} />
                <div>Total Mythics Minted: {finalTotals.totalMinted}</div>
            </div>
            <div className="stat-container" style={{ height: '200px' }}>
                <Line data={generateChartData('uniqueMinted')} options={options} />
                <div>Unique Mythics Minted: {finalTotals.uniqueMinted}</div>
            </div>
            <div className="stat-container" style={{ height: '200px' }}>
                <Line data={generateChartData('totalOwners')} options={options} />
                <div>Total Mythics Owners: {finalTotals.totalOwners}</div>
            </div>
        </div>
    );
};

export default Stats;