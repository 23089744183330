import { db } from './firebase.js'; // Import the firebase database instance
import { collection, getDocs } from 'firebase/firestore';

export const fetchLeaderboardData = async () => {
  try {
    const nftsCollection = collection(db, 'nfts');
    const nftsSnapshot = await getDocs(nftsCollection);
    
    const ownerData = {};

    nftsSnapshot.forEach(doc => {
        const nft = doc.data();
        const owner = nft.owner; // Assuming there's an 'owner' field in each NFT document
        
        // Log the document ID for debugging
        console.log('Document ID:', doc.id);
        
        // Check if the owner field is valid
        if (owner && typeof owner === 'string') {
          const rank = parseInt(nft.rank);
      
          if (!ownerData[owner]) {
            ownerData[owner] = {
              totalNFTs: 0,
              uniqueNFTs: new Set(),
              totalRank: 0,
              strikeRank: 0,
              highestRank: 0,
              consecutiveRanks: new Set()
            };
          }
      
          const data = ownerData[owner];
          data.totalNFTs += 1;
          data.uniqueNFTs.add(rank);
          data.totalRank += rank;
          data.consecutiveRanks.add(rank);
          data.highestRank = Math.max(data.highestRank, rank);
          data.points = (data.highestRank * data.strikeRank) + data.totalRank;
      
          let maxConsecutive = 1;
          let currentConsecutive = 1;
          [...data.consecutiveRanks].sort((a, b) => a - b).forEach((r, i, arr) => {
            if (i > 0 && arr[i - 1] === r - 1) {
              currentConsecutive++;
              maxConsecutive = Math.max(maxConsecutive, currentConsecutive);
            } else {
              currentConsecutive = 1;
            }
          });
          data.strikeRank = maxConsecutive;
        } else {
          // Log the invalid owner value for debugging
          console.log('Invalid owner value for document ID:', doc.id, 'Owner:', owner);
        }
      });
      
    const ownerStatisticsArray = Object.entries(ownerData).map(([owner, data]) => ({
      owner,
      totalNFTs: data.totalNFTs,
      uniqueNFTs: data.uniqueNFTs.size,
      totalRank: data.totalRank,
      strikeRank: data.strikeRank,
      highestRank: data.highestRank
    }));

    console.log('Owner Statistics Array:', ownerStatisticsArray);

    return {
      count: nftsSnapshot.size,
      ownerStatisticsArray
    };

  } catch (error) {
    console.error('Error fetching leaderboard data:', error);
    throw error; // Rethrow the error to handle it in the calling component
  }
};
