// firebase.js (Client-side)
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCIoUofYPNTPR1dZ8uRu8be94MKuNrqtyw",
    authDomain: "yellow-nfts.firebaseapp.com",
    projectId: "yellow-nfts",
    storageBucket: "yellow-nfts.appspot.com",
    messagingSenderId: "618392540560",
    appId: "1:618392540560:web:b7874f19481778fd084f15"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);

export { db, analytics };